import {
  Button,
  colors,
  Container as RawContainer,
  mediaQueries,
  TertiaryLink,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Section = styled.section<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => bgColor || colors.green1};
`;

export const Container = styled(RawContainer)`
  display: flex;
  flex-direction: column;
  padding: 48px 24px 32px;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    padding: 48px 24px 32px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 73px 121px;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  img {
    max-height: 512px;
  }

  ${mediaQueries.forTabletVerticalUp} {
    flex: 1;
    margin-bottom: 0;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex: 2;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.forTabletVerticalUp} {
    flex: 1;
    padding-left: 30px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex: 3;
    padding-left: 60px;
  }
`;

export const Title = styled.h2`
  ${typography.h2Text}
  color: ${colors.teal4};
  margin-bottom: 16px;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 28rem;
    text-align: left;
  }
`;

export const Content = styled.div`
  * {
    ${typography.bodyText}
    color: ${colors.gray4};
    margin-bottom: 10px;

    ${mediaQueries.forTabletVerticalUp} {
      margin-bottom: 24px;
      max-width: 30rem;
    }
  }
`;

export const AppStoreLink = styled.div`
  display: none;
  margin-bottom: 24px;
  width: fit-content;

  ${mediaQueries.forTabletVerticalUp} {
    cursor: pointer;
    display: block;
  }
`;

export const AppStoreButton = styled(Button)`
  display: block;
  margin-bottom: 16px;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;

export const ProceedCTADesktop = styled.div`
  color: ${colors.green4};
  cursor: pointer;
  display: none;
  text-align: center;
  text-align: left;
  text-decoration: none;
  width: fit-content;

  ${mediaQueries.forTabletVerticalUp} {
    display: block;
  }
`;

export const ProceedCTAMobile = styled(TertiaryLink)`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;
