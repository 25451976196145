/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  colors,
  Container as RawContainer,
  mediaQueries,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

// MAIN COMPONENT
export const Section = styled.section`
  background-color: ${colors.white};
`;

export const Container = styled(RawContainer)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 24px;

  & > div:not(:last-of-type) {
    margin-bottom: 24px;
  }

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    justify-content: space-evenly;
    padding: 64px;

    & > div {
      margin-bottom: 0;
    }
  }
`;

// ITEM COMPONENT

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ImagesWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 56px;
`;

export const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 16px;
  max-width: 320px;
`;

export const Title = styled.h2`
  ${typography.subtitle}
  color: ${colors.gray5};
  margin-bottom: 4px;
  text-align: left;
`;

export const Content = styled.div`
  * {
    ${typography.bodyText}
    color: ${colors.gray4};
  }
`;
