import { Col } from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { IGatsbyImage } from 'utils/types';

import * as S from './styles';

export type ContentWithIconItemProps = {
  title?: string;
  content?: string;
  image?: IGatsbyImage;
};

const ContentWithIconItem = (props: ContentWithIconItemProps) => {
  const { title, content, image } = props;

  return (
    <Col xs={12} md={6} lg={4}>
      <S.ItemWrapper>
        <S.ImagesWrapper>
          {image ? (
            <GatsbyImage
              data-testid="image"
              objectFit="contain"
              alt={image.description || 'Desktop Image'}
              image={image.gatsbyImageData}
              loading="eager"
            />
          ) : null}
        </S.ImagesWrapper>
        <S.ContentWrapper>
          {title ? <S.Title data-testid="title">{title}</S.Title> : null}
          {content ? (
            <S.Content
              data-testid="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : null}
        </S.ContentWrapper>
      </S.ItemWrapper>
    </Col>
  );
};

export default ContentWithIconItem;
