import MobileAppHero, {
  MobileAppHeroProps,
} from 'components/Hero/MobileAppHero';
import React from 'react';
import { CTA, PageSectionType } from 'utils/types';

interface ExtendedPageSectionType extends PageSectionType {
  listOfLinks: CTA[];
}

type MobileAppHeroContainerProps = {
  section: ExtendedPageSectionType;
};

const MobileAppHeroContainer = (props: MobileAppHeroContainerProps) => {
  const { section } = props;
  const { description, desktopImages, mobileImages, listOfLinks, headline } =
    section || {};
  const [proceedCTA, storeLink] = listOfLinks || [];

  const componentProps: MobileAppHeroProps = {
    backgroundColor: '#EDF5F1', // TODO: Get it from Contentful
    content: description?.childMarkdownRemark?.html,
    desktopImage: desktopImages?.length ? desktopImages[0] : undefined,
    proceedCTA: proceedCTA,
    storeCTA: storeLink,
    storeImage: mobileImages?.length ? mobileImages[0] : undefined,
    title: headline,
  };

  return <MobileAppHero {...componentProps} />;
};

export default MobileAppHeroContainer;
