import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CTA, IGatsbyImage } from 'utils/types';

import * as S from './styles';

export type MobileAppHeroProps = {
  backgroundColor: string;
  content?: string;
  desktopImage?: IGatsbyImage;
  proceedCTA?: CTA;
  storeCTA?: CTA;
  storeImage?: IGatsbyImage;
  title?: string;
};

const MobileAppHero = (props: MobileAppHeroProps) => {
  const {
    backgroundColor,
    content,
    desktopImage,
    proceedCTA,
    storeImage,
    storeCTA,
    title,
  } = props;

  const trackDownloadApp = async () => {
    await ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        category: ANALYTICS.CATEGORIES.MOBILE_APP,
        label: ANALYTICS.LABELS.DOWNLOAD_APP,
      },
    });
  };

  const trackProceedWeb = async () => {
    await ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        category: ANALYTICS.CATEGORIES.MOBILE_APP,
        label: ANALYTICS.LABELS.PROCEED_WEB,
      },
    });
  };

  const handleOnProceed = async () => {
    const url = proceedCTA?.url;
    if (url) {
      await trackProceedWeb();
      navigate(url);
    }
  };

  const handleOnDownload = async () => {
    const url = storeCTA?.url;
    if (url) {
      await trackDownloadApp();
      navigate(url);
    }
  };

  return (
    <S.Section bgColor={backgroundColor}>
      <S.Container>
        <S.ImagesWrapper>
          {desktopImage ? (
            <GatsbyImage
              alt={desktopImage.description || 'Desktop Image'}
              image={desktopImage.gatsbyImageData}
              loading="eager"
            />
          ) : null}
        </S.ImagesWrapper>
        <S.ContentWrapper>
          {title ? <S.Title data-testid="Title">{title}</S.Title> : null}
          {content ? (
            <S.Content
              data-testid="Content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : null}
          {storeImage && storeCTA ? (
            <S.AppStoreLink
              onClick={handleOnDownload}
              data-testid="AppStoreLink"
            >
              <GatsbyImage
                alt={storeImage.description || 'AppStoreLink'}
                image={storeImage.gatsbyImageData}
                loading="eager"
              />
            </S.AppStoreLink>
          ) : null}
          {storeCTA ? (
            <S.AppStoreButton onClick={handleOnDownload} appearance="primary">
              {storeCTA?.text}
            </S.AppStoreButton>
          ) : null}
          {proceedCTA ? (
            <>
              <S.ProceedCTAMobile
                onClick={handleOnProceed}
                data-testid="ProceedCTAMobile"
              >
                {proceedCTA.text}
              </S.ProceedCTAMobile>
              <S.ProceedCTADesktop
                onClick={handleOnProceed}
                data-testid="ProceedCTADesktop"
              >
                {proceedCTA.text}
              </S.ProceedCTADesktop>
            </>
          ) : null}
        </S.ContentWrapper>
      </S.Container>
    </S.Section>
  );
};

export default MobileAppHero;
