import ContentWithIcon, {
  ContentWithIconProps,
} from 'components/ContentWithIcon';
import { ContentWithIconItemProps } from 'components/ContentWithIcon/ContentWithIconItem';
import React from 'react';
import { PageSectionType } from 'utils/types';

type MobileAppFeaturesContainerProps = {
  section: PageSectionType;
};

const MobileAppFeaturesContainer = (props: MobileAppFeaturesContainerProps) => {
  const {
    section: { listOfContentWithImages = [] },
  } = props;

  const items: ContentWithIconItemProps[] = listOfContentWithImages.map(
    ({ image, heading, description }) => ({
      content: description?.childMarkdownRemark?.html,
      image: image,
      title: heading,
    }),
  );

  const componentProps: ContentWithIconProps = {
    items: items,
  };

  return <ContentWithIcon {...componentProps} />;
};

export default MobileAppFeaturesContainer;
