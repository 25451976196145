import Layout from 'components/Layout';
import { SEO } from 'components/SEO';
import {
  MobileAppHeroContainer,
  MobileAppFeaturesContainer,
} from 'containers/MobileAppContainer';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { track } from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { PageSectionType } from 'utils/types';

type Props = {
  data: {
    content: {
      sections: PageSectionType[];
      title: string;
    };
  };
};

interface Sections {
  [key: string]: any;
}

const SECTIONS: Sections = {
  'mobile-app-hero-section': MobileAppHeroContainer,
  'mobile-app-value-props': MobileAppFeaturesContainer,
};

type FactoryProps = {
  sections: PageSectionType[];
};

const Factory = ({ sections = [] }: FactoryProps) => (
  <>
    {sections.map((section) => {
      const { slug } = section;
      const component = slug ? SECTIONS[`${slug}`] : null;
      return component ? React.createElement(component, { section }) : <></>;
    })}
  </>
);

const DownloadMobileAppPage = ({ data }: Props): React.ReactElement | null => {
  const { content } = data;
  const { title, sections } = content;

  useEffect(() => {
    track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        category: ANALYTICS.CATEGORIES.MOBILE_APP,
        label: ANALYTICS.LABELS.DOWNLOAD_APP_PAGE,
      },
    });
  }, []);

  return (
    <div>
      <SEO title={title} />
      <Layout>
        <Factory sections={sections} />
      </Layout>
    </div>
  );
};

export default DownloadMobileAppPage;

export const data = graphql`
  query {
    content: contentfulPage(pageId: { eq: "Download Mobile App" }) {
      title
      sections {
        sectionId
        featureFlag
        componentName
        componentVariation
        title
        slug
        headline
        subHeadline
        description {
          childMarkdownRemark {
            html
          }
          description
        }
        subHeadlineDescription {
          childMarkdownRemark {
            html
          }
          subHeadlineDescription
        }
        mainCta {
          text
          url
          appearance
        }
        listOfContentWithImages {
          slug
          heading
          subheading
          title
          description {
            childMarkdownRemark {
              html
            }
            description
          }
          image {
            gatsbyImageData(placeholder: BLURRED, width: 414, quality: 100)
            description
          }
          cta {
            text
            url
            appearance
          }
          subheading
          number
          identifier
          featureFlag
        }
        desktopImages {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
          description
        }
        mobileImages {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
          description
        }
        listOfLinks {
          text
          url
        }
        backgroundColor
      }
    }
  }
`;
