import React from 'react';

import ContentWithIconItem, {
  ContentWithIconItemProps,
} from './ContentWithIconItem';
import * as S from './styles';

export type ContentWithIconProps = {
  items: ContentWithIconItemProps[];
};

const ContentWithIcon = (props: ContentWithIconProps) => {
  const { items = [] } = props;

  return (
    <S.Section>
      <S.Container>
        {items.map((item, idx) => (
          <ContentWithIconItem key={idx} {...item} />
        ))}
      </S.Container>
    </S.Section>
  );
};

export default ContentWithIcon;
